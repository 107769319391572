<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb>
            <breadcrumb-item v-for="breadcrumb in info_page.breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2">
            <div class="box-form">
                <vue-topprogress ref="topProgress"></vue-topprogress>

                <form>
                    <div class="row">
                        <div class="col-md-4">
                            <fg-input type="text"
                                      :name="$t('form.name')"
                                      :label="$t('form.name')"
                                      :placeholder="$t('form.name')"
                                      v-validate="modelValidations.name"
                                      :error="getError($t('form.name'))"
                                      v-model="rateplan.name">
                            </fg-input>
                        </div>
                        <div class="col-md-2">
                            <fg-input type="text"
                                      @input="rateplan.cod = rateplan.cod.toUpperCase()"
                                      :name="$t('contract.form.code')"
                                      :label="$t('contract.form.code')"
                                      :placeholder="$t('contract.form.code')"
                                      v-validate="modelValidations.code"
                                      :error="getError($t('contract.form.code'))"
                                      v-model="rateplan.cod"
                                      :disabled="(info_page.action==='edit')">
                            </fg-input>
                        </div>
                        <div class="col-md-4 custom-error mbis-0">
                            <label>{{$t('contract.form.vigency')}}</label>
                            <fg-input>
                                <el-date-picker v-model="rateplan.vigency"
                                                type="daterange" placeholder="Inicio"
                                                format="dd-MM-yyyy"
                                                :picker-options="pickerOptions2"
                                                range-separator="a"
                                                start-placeholder="Fecha inicio"
                                                end-placeholder="Fecha fin"
                                                value-format="yyyy-MM-dd"
                                                :default-time="['00:00:00', '00:00:00']"
                                >
                                </el-date-picker>
                            </fg-input>
                            <label class="text-danger" v-if="errores.vigencyText">El campo Vigencia es requerido</label>

                        </div>
                        <div class="col-md-2">
                            <div class="form-check is_public">
                                <label for="is_public" class="form-check-label check-admin">
                                    <input class="form-check-input" type="checkbox" id="is_public"
                                           v-model="rateplan.is_public">
                                    <span class="form-check-sign"></span> Público
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-show="vars.rowLink">

                        <div class="col-md-4">
                            <div class="form-group has-label">
                                <label>{{$t('contract.form.vinculo')}}</label>
                                <el-select class="select-default" size="large"
                                           :placeholder="$t('contract.form.vinculo')"
                                           v-model="rateplan.rateplan_link.rateplan" @change="contractType()">
                                    <el-option v-for="option in vars.rateplans" class="select-default"
                                               :value="option._id"
                                               :label="option.name" :key="option._id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-4 custom-error">
                            <div class="form-group has-label pt-custom"
                                 v-show="rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan!==''">
                                <el-select class="select-default" size="large"
                                           :placeholder="$t('contract.form.link_type')"
                                           :name="$t('contract.form.link_type')"
                                           v-model="rateplan.rateplan_link.type">
                                    <el-option v-for="option in generalInfo.format_payment" class="select-default"
                                               :value="option._id"
                                               :label="option.name" :key="option._id">
                                    </el-option>
                                </el-select>
                                <label class="text-danger" v-show="errores.linksType">El campo tipo de link es
                                    obligatorio</label>
                            </div>
                        </div>
                        <div class="col-md-4  pt-custom  custom-error"
                             v-show="rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan!==''">

                            <fg-input type="text"
                                      class="mb-0"
                                      :name="$t('contract.form.link_value')"
                                      :placeholder="$t('contract.form.link_value')"
                                      v-model="rateplan.rateplan_link.value"
                                      :disabled="(rateplan.rateplan_link.type==='=')"
                            >
                            </fg-input>
                            <label class="text-danger" v-show="errores.linksValue">El campo cantidad es
                                obligatorio</label>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group has-label">
                                <label>{{$t('contract.form.type')}}</label>

                                <fg-input v-show="rateplan.contract_base && info_page.action!=='edit'"
                                          v-validate="modelValidations.meal_plan" :name="$t('contract.form.type')"
                                          v-model="rateplan.meal_plan"
                                          :error="getError($t('contract.form.type'))">
                                    <el-select class="select-default" size="large"
                                               :placeholder="$t('contract.form.type')"
                                               v-validate="modelValidations.meal_plan" :name="$t('contract.form.type')"
                                               v-model="rateplan.meal_plan" :error="getError($t('contract.form.type'))"
                                               @change="checkType()"
                                    >
                                        <el-option v-for="option in vars.meal_plans" class="select-default"
                                                   :value="option._id"
                                                   :label="option.name" :key="option._id">
                                        </el-option>
                                    </el-select>
                                </fg-input>


                                <fg-input v-show="!rateplan.contract_base || info_page.action==='edit'"
                                          :value="contractMealplan(rateplan.meal_plan)"
                                          disabled="true">
                                </fg-input>


                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group has-label">
                                <label>{{$t('contract.form.currency')}}</label>


                                <fg-input v-show="(info_page.action==='add') && rateplan.contract_base"
                                          v-validate="modelValidations.currency" :name="$t('contract.form.currency')"
                                          v-model="rateplan.currency"
                                          :error="getError($t('contract.form.currency'))">
                                    <el-select class="select-default" size="large"
                                               :placeholder="$t('contract.form.currency')"
                                               v-validate="modelValidations.currency"
                                               :name="$t('contract.form.currency')"
                                               v-model="rateplan.currency"
                                               :error="getError($t('contract.form.currency'))"
                                               @change="filterRateplans()">
                                        <el-option v-for="option in generalInfo.currencys" class="select-default"
                                                   :value="option._id"
                                                   :label="option.name" :key="option._id">
                                        </el-option>
                                    </el-select>
                                </fg-input>

                                <fg-input v-show="(info_page.action==='add') && !rateplan.contract_base"
                                          v-validate="modelValidations.currency" :name="$t('contract.form.currency')"
                                          v-model="rateplan.currency"
                                          :error="getError($t('contract.form.currency'))" disabled="true">
                                </fg-input>


                                <fg-input type="text"
                                          v-model="rateplan.currency"
                                          :disabled="(info_page.action==='edit')"
                                          v-show="info_page.action==='edit'"
                                >
                                </fg-input>
                            </div>
                        </div>

                        <div class="col-md-4">

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12"
                             v-show="!rateplan.rateplan_link.rateplan || rateplan.rateplan_link.rateplan===''">
                            <div class="box-header">
                                <div class="form-check">
                                    <label for="is_public2" class="form-check-label check-admin subtitle">
                                        <input class="form-check-input" type="checkbox" id="is_public2"
                                               v-model="vars.roomsLinks" @change="changeLinks()">
                                        <span class="form-check-sign"></span>
                                        Habitaciones vinculadas
                                    </label>
                                </div>
                                <label class="lbl-subtitle">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Todas las habitaciones
                                    se vinculan a la habitación base.
                                </label>
                            </div>
                        </div>
                        <div class="box-contain-rooms items"
                             :class="vars.allInclusive && rateplan.infoRooms.length?'scoll-apply':''">
                            <div class="item-room" v-for="(infoRoom, idx_) in  rateplan.infoRooms">
                                <div class="header-rooms" v-if="idx_===0">
                                    <div class="inside-left head-rooms">
                                        <div class="box-room">
                                            <label>{{$t('contract.form.add_rooms')}}</label>
                                        </div>
                                        <div class="box-ep" v-if="!vars.allInclusive">
                                            <div class="item-rate" style="width: 120px">
                                                <label>{{$t('contract.form.base_pax')}}</label>
                                            </div>
                                            <div class="item-rate">
                                                <label>{{$t('contract.form.rate_base')}}</label>
                                            </div>
                                            <div class="item-rate">
                                                <label>{{$t('contract.form.extra_pax')}}</label>
                                            </div>
                                            <div class="item-rate"
                                                 v-if="!vars.roomsLinks && (!rateplan.rateplan_link.rateplan || rateplan.rateplan_link.rateplan==='')">
                                                <label>{{$t('contract.form.rate_base')}}</label>
                                            </div>
                                            <div class="item-rate"
                                                 v-if="!vars.roomsLinks && (!rateplan.rateplan_link.rateplan || rateplan.rateplan_link.rateplan==='')">
                                                <label>{{$t('contract.form.extra_pax')}}</label>
                                            </div>

                                        </div>
                                        <div class="box-resume-ep" v-if="!vars.allInclusive && vars.roomsLinks"
                                             v-show="!rateplan.rateplan_link.rateplan || rateplan.rateplan_link.rateplan===''">
                                            <div class="item-resume">
                                                <label>{{$t('contract.form.rate_base')}}</label>
                                            </div>
                                            <div class="item-resume">
                                                <label>{{$t('contract.form.extra_pax')}}</label>
                                            </div>
                                        </div>
                                        <div class="box-ai" v-if="vars.allInclusive && infoRoom.assignments.length>0">
                                            <div class="item-rate" v-for="headerIs in vars.headers">
                                                <label>{{headerIs}}</label>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="box-extras head-rooms" v-if="vars.allInclusive">
                                        <div class="item-rate" v-for="headerIs in vars.headers_extras">
                                            <label>{{headerIs}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row-item">
                                    <div class="inside-left" :style="'width:'+ calcWidth(170)+'px'">
                                        <div class="box-room" :class="vars.allInclusive?'super-top':''">


                                            <i class="fa fa-star space-icon" v-show="vars.roomsLinks &&
                                        infoRoom.isBaseRate && !rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan===''"
                                               v-tooltip.bottom="'Habitación base'"></i>
                                            <fg-input type="text"
                                                      class="input-false-default primary"
                                                      :class="vars.roomsLinks && infoRoom.isBaseRate?'with-icon':'no-icon'"
                                                      v-if="(vars.rateplanLink || !isAvailable(infoRoom.room)) &&  infoRoom.room!==''"
                                                      disabled="true"
                                                      :value="roomName(infoRoom.room)" >
                                            </fg-input>


                                            <el-select class="select-default primary"
                                                       :class="vars.roomsLinks && infoRoom.isBaseRate?'with-icon':'no-icon'"
                                                       size="large"
                                                       v-if="(!vars.rateplanLink || infoRoom.room==='') && isAvailable(infoRoom.room)"
                                                       :placeholder="(idx_===0)?'Seleccionar hab. base':'Seleccionar habitación'"
                                                       v-model="infoRoom.room" @change="roomInfo(idx_)">
                                                <el-option v-for="(option, idx) in vars.rooms" class="select-default"
                                                           :value="option._id"
                                                           v-show="option.status===true" :label="option.name"
                                                           :key="idx">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="box-ep" v-if="!vars.allInclusive">
                                            <div class="item-rate" style="width: 120px">
                                                <div class="all-container" v-if="!vars.rateplanLink">
                                                    <el-select class="select-default all-width" size="large" placeholder="Seleccionar"
                                                               :name="$t('contract.form.link_type')"
                                                               v-model="infoRoom.base_pax" >
                                                        <el-option v-for="number in infoRoom.max_capacity" v-if="number>1"
                                                                   class="text-center"
                                                                   :value="number"
                                                                   :label="number" :key="number">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div class="all-container" v-if="vars.rateplanLink">
                                                    <label>{{infoRoom.base_pax}}</label>
                                                </div>
                                            </div>



                                            <div class="item-rate">
                                                <div class="all-container text-left" v-if="!vars.rateplanLink">



                                                    <fg-input type="text"
                                                              class="input-default"
                                                              :class="checkClassBase(vars.roomsLinks, infoRoom.isBaseRate)"
                                                              v-model="infoRoom.base_rate" @keypress="isNumberKey($event)"  @input="infoRoom.base_rate = toFixed(infoRoom.base_rate, 2)">
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"
                                                               :name="$t('contract.form.link_type')"
                                                               :class="!vars.roomsLinks?'simple-class':''"
                                                               v-show="!infoRoom.isBaseRate "

                                                               v-model="infoRoom.room_link_type">
                                                        <el-option v-for="option in generalInfo.format_payment"
                                                                   :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div class="all-container" v-if="vars.rateplanLink">
                                                    <!--   <label>{{calculateVinculates(idx_, 'base_rate')}} </label>-->
                                                    <label>{{costVinculate(infoRoom.room, 'base_rate')}}</label>
                                                </div>


                                            </div>
                                            <div class="item-rate">
                                                <div class="all-container" v-if="!vars.rateplanLink">
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              :class="checkClassBase(true, infoRoom.isBaseRate)"
                                                              v-model="infoRoom.extra_pax" @keypress="isNumberKey($event)"  @input="infoRoom.extra_pax = toFixed(infoRoom.extra_pax, 2)">
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"
                                                               :name="$t('contract.form.link_type')"
                                                               v-model="infoRoom.room_link_type_pax">
                                                        <el-option v-for="option in generalInfo.format_payment"
                                                                   class="select-default" :value="option._id"
                                                                   :class="!vars.roomsLinks?'simple-class':''"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div class="all-container" v-if="vars.rateplanLink">
                                                    <!--<label>{{calculateVinculates(idx_, 'extra_pax')}}</label>-->
                                                    <label>{{costVinculate(infoRoom.room, 'extra_pax')}}</label>
                                                </div>
                                            </div>
                                            <div class="item-rate"
                                                 v-if="!vars.roomsLinks && (!rateplan.rateplan_link.rateplan || rateplan.rateplan_link.rateplan==='')">
                                                <div class="all-container">
                                                    <label>{{moneyView(infoRoom.base_rate)}}</label>
                                                </div>
                                            </div>
                                            <div class="item-rate"
                                                 v-if="!vars.roomsLinks && (!rateplan.rateplan_link.rateplan || rateplan.rateplan_link.rateplan==='')">
                                                <div class="all-container">
                                                    <label>{{gapFunctionView(infoRoom.base_rate, infoRoom.extra_pax,
                                                        infoRoom.room_link_type_pax)}}</label>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="box-ai" v-if="vars.allInclusive">
                                            <div class="item-rate"
                                                 v-for="(assignment, asigKey) in infoRoom.assignments">

                                                <div class="all-container text-left" v-if="!vars.rateplanLink">
                                                    <fg-input type="text" v-if="assignment.cant===2"
                                                              class="input-default"
                                                              :class="checkClassBase(vars.roomsLinks, infoRoom.isBaseRate)"
                                                              v-model="infoRoom.base_rate" @keypress="isNumberKey($event)" @input="infoRoom.base_rate = toFixed(infoRoom.base_rate, 2)">
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"
                                                               :name="$t('contract.form.link_type')"
                                                               v-model="infoRoom.room_link_type"
                                                               v-if="assignment.cant===2 && !infoRoom.isBaseRate"
                                                               :class="!vars.roomsLinks?'simple-class':''">
                                                        <el-option v-for="option in generalInfo.format_payment"
                                                                   :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                    <fg-input type="text"
                                                              v-if="assignment.cant!==2"
                                                              class="input-default"
                                                              v-model="assignment.value" @keypress="isNumberKey($event)" @input="assignment.value = toFixed(assignment.value, 2)"
                                                    >
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"
                                                               :name="$t('contract.form.link_type')"
                                                               v-model="assignment.link" v-if="assignment.cant!==2"
                                                    >
                                                        <el-option v-for="option in generalInfo.format_payment"
                                                                   :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>

                                                <div class="all-container" v-if="vars.rateplanLink">
                                                    <!-- <label v-if="assignment.cant!==2">{{calculateVinculates(idx_, asigKey)}}</label>
                                                     <label v-else>{{calculateVinculates(idx_, asigKey)}}</label>-->
                                                    <label v-if="assignment.cant!==2">{{costVinculate(infoRoom.room,
                                                        assignment.cant)}}</label>
                                                    <label v-else>{{costVinculate(infoRoom.room, 'base_rate')}}</label>
                                                </div>


                                            </div>
                                        </div>


                                        <div class="box-resume-ep" v-if="!vars.allInclusive && vars.roomsLinks"
                                             v-show="!rateplan.rateplan_link.rateplan || rateplan.rateplan_link.rateplan===''">
                                            <div class="item-resume">
                                                <label>{{calculateVinculates(idx_, 'base_rate')}}</label>
                                            </div>
                                            <div class="item-resume">
                                                <label>{{calculateVinculates(idx_, 'extra_pax')}}</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="box-extras" v-if="vars.allInclusive &&  vars.headers_extras.length>0">
                                        <div class="item-rate" v-show="infoRoom.jrs_status">
                                            <div class="all-container" v-if="!vars.rateplanLink">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          v-model="infoRoom.jrs_price" @keypress="isNumberKey($event)" @input="infoRoom.jrs_price = toFixed(infoRoom.jrs_price, 2)">
                                                </fg-input>
                                                <el-select class="select-default" size="large" placeholder="GAP"
                                                           :name="$t('contract.form.link_type')"
                                                           v-model="infoRoom.jr_link_type">
                                                    <el-option v-for="option in generalInfo.format_payment"
                                                               class="select-default" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>

                                            <div class="all-container" v-if="vars.rateplanLink">
                                                <!-- <label>{{  calculateVinculates(idx_, 'jrs')}}</label>-->
                                                <label v-if="infoRoom.jrs_status">{{ costVinculate(infoRoom.room, 'jrs')}}</label>
                                            </div>

                                        </div>
                                        <div class="item-rate" v-show="infoRoom.children_status">
                                            <div class="all-container" v-if="!vars.rateplanLink">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          v-model="infoRoom.children_price"  @keypress="isNumberKey($event)" @input="infoRoom.children_price = toFixed(infoRoom.children_price, 2)">
                                                </fg-input>
                                                <el-select class="select-default" size="large" placeholder="GAP"
                                                           :name="$t('contract.form.link_type')"
                                                           v-model="infoRoom.children_link_type">
                                                    <el-option v-for="option in generalInfo.format_payment"
                                                               class="select-default" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="all-container" v-if="vars.rateplanLink">
                                                <!--<label>{{calculateVinculates(idx_, 'childs')}}</label>-->
                                                <label  v-if="infoRoom.children_status">{{ costVinculate(infoRoom.room, 'children')}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-minus">
                                        <i class="fa fa-minus" @click="deleteRoom(idx_)"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="box-resume-ai" v-if="vars.allInclusive  && !vars.rateplanLink">
                                <div class="item-header">
                                    <div class="bx-left" :style="'width:'+ calcWidth(170)+'px'">
                                        <div class="item-room">
                                            <label>Habitación base</label>
                                        </div>
                                        <div class="item-resume" v-for="headerIs in vars.headers">
                                            <label>{{headerIs}}</label>
                                        </div>
                                    </div>
                                    <div class="item-resume" v-for="headerIs in vars.headers_extras">
                                        <label>{{headerIs}}</label>
                                    </div>

                                </div>
                                <div class="element-room" v-for="(infoRoom, idx_) in  rateplan.infoRooms">
                                    <div class="bx-left" :style="'width:'+ calcWidth(170)+'px'">
                                        <div class="item-room">
                                            <label class="fw-300"> {{roomName(infoRoom.room)}} </label>
                                        </div>
                                        <div class="item-resume" v-for="(assignment, asigKey) in infoRoom.assignments">
                                            <label v-if="assignment.cant!==2">{{calculateVinculates(idx_,
                                                asigKey)}}</label>
                                            <label v-else>{{calculateVinculates(idx_, asigKey)}}</label>
                                        </div>
                                    </div>

                                    <div class="item-resume" v-show="infoRoom.jrs_status">
                                        <label>{{calculateVinculates(idx_, 'jrs')}}</label>
                                    </div>
                                    <div class="item-resume" v-show="infoRoom.children_status">
                                        <label>{{calculateVinculates(idx_, 'childs')}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 custom-error pl-3" v-if="errores.roomsText">
                            <label class="text-danger">{{errores.roomsText}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-right">
                            <a class="btn-plus" v-show="rateplan.infoRooms.length < vars.rooms.length - 1 ">
                                <i class="fa fa-plus" @click="addRoom()" v-tooltip.bottom="'Agregar habitación'"></i>
                            </a>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-4 custom-error">
                            <label>{{$t('contract.form.type_payment')}}</label>
                            <!--<div class="form-check" v-for="payment in vars.payments" v-if="payment.status" v-show="rateplan.currency!=='PTS'">-->
                            <div class="form-check" v-for="payment in vars.payments" v-if="payment.status">
                                <label :for="payment._id" class="form-check-label">
                                    <input class="form-check-input" type="checkbox" :id="payment._id"
                                           v-model="rateplan.payment_options" :value="payment._id"
                                           @input="errores.payments = false">
                                    <span class="form-check-sign"></span>{{payment.name}}
                                </label>
                                <div v-if="payment._id==='STRIPE' && rateplan.payment_options.indexOf(payment._id)>=0 && currentUser.is_superadmin" >
                                    <div class="row">
                                        <div class="col-md-12 col-lg-7">
                                            <fg-input type="text"
                                      label="Agregar comisión especial %"
                                      v-model="rateplan.comission.stripe">
                            </fg-input> 
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <!--<div class="form-check"  v-show="rateplan.currency==='PTS'">
                                <label for="PTS" class="form-check-label">
                                    <input class="form-check-input" type="checkbox" id="PTS"
                                           v-model="rateplan.payment_options" value="PTS"
                                           @input="errores.payments = false" checked>
                                    <span class="form-check-sign"></span>Pago con puntos

                                </label>
                            </div>-->

                            <label class="text-danger " v-show="errores.comissionType">El valor de la comisión debe ser númerico</label>

                            <span v-if="!vars.payments_status"
                                  class="item-empty">No tienes agregado ningún tipo de pago</span>

                            <label class="text-danger " v-show="errores.payments">Es obligatorio agregar un tipo de pago
                                para continuar</label>
                        </div>


                        <div class="col-md-4">
                            <div class="form-group has-label">
                                <label>{{$t('contract.form.politics')}}</label>
                                <fg-input v-model="rateplan.cancellation_policy"
                                          v-validate="modelValidations.politics"
                                          :name="$t('contract.form.politics')"
                                          :error="getError($t('contract.form.politics'))">
                                    <el-select class="select-default" size="large"
                                               v-model="rateplan.cancellation_policy"
                                               v-validate="modelValidations.politics"
                                               :name="$t('contract.form.politics')"
                                               :error="getError($t('contract.form.politics'))">
                                        <el-option v-for="(option, idx) in vars.annulments" class="select-default"
                                                   :value="option._id"
                                                   :label="option.name" :key="idx">
                                        </el-option>
                                    </el-select>
                                </fg-input>
                            </div>
                        </div>
                    </div>
                    <div class="row pl-3" v-show="rateplan.contract_base && vars.roomsLinks">
                        <div class="col-md-6 mt-3  custom-error">
                            <label class="mb-2 subtitle">Tarifas</label>
                            <div class="form-rates-ext">
                                <div class="item-group" v-for="(racke, ky) in rateplan.rates_list">
                                    <label>{{assigNameRack(ky)}}</label>
                                    <fg-input v-model="rateplan.rates_list[ky]" @input="errores.racks = false">
                                    </fg-input>
                                    <span class="fa fa-minus" @click="deleteRack(ky)"></span>
                                </div>
                                <div class="item-group">
                                    <span class="fa fa-plus-circle plus-item" @click="addRack"></span>
                                </div>
                            </div>
                            <label class="text-danger " v-show="errores.racks">Se deben llenar todos los campos antes de
                                continuar</label>
                        </div>
                    </div>


                    <div class="text-center mt-3 mb-3">
                        <button type="submit" class="btn btn-success btn-fill btn-wd mr-2"
                                @click.prevent="sendForm(info_page.action)">
                            {{info_page.button}}
                        </button>
                        <button type="buttton" class="btn btn-danger btn-fill btn-wd"
                                @click.prevent="$router.push({name: 'Contracts'})">
                            {{$t('form.cancel')}}
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import {Select, Option, Tag, DatePicker} from 'element-ui'
    import {Checkbox, Breadcrumb, BreadcrumbItem} from 'src/components/index'
    import RoomService from '../../../js/services/RoomService';
    import ContractService from '../../../js/services/ContractService';
    import AnnulmentService from '../../../js/services/AnnulmentService';
    import PropertyService from '../../../js/services/PropertyService';
    import RateplanService from '../../../js/services/RateplanService';
    import moment from 'moment';

    import RateplanFunctions from '../../../js/functions/RateplanFunctions';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';


    import {vueTopprogress} from 'vue-top-progress';
    import { mapGetters } from "vuex";


    const roomService = new RoomService();
    const contractService = new ContractService();
    const annulmentService = new AnnulmentService();
    const propertyService = new PropertyService();
    const rateplanService = new RateplanService;
    const rateplanFuntions = new RateplanFunctions;
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    export default {
        components: {
            Checkbox,
            Breadcrumb,
            BreadcrumbItem,
            [DatePicker.name]: DatePicker,
            [Option.name]: Option,
            [Select.name]: Select,
            [Tag.name]: Tag,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser","selected_property"]),

        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                info_page: {
                    original_info:{},
                    section_name: 'rateplan',
                    action: 'add',
                    header: this.$t('contract.new.title'),
                    button: this.$t('form.save'),
                    breadcrumbs: [
                        {
                            name: this.$t('property.index.home'),
                            path: '/admin/properties',
                            type: '',
                        },

                        {
                            name: this.$t('contract.index.title'),
                            path: '/admin/contracts',
                            type: '',
                        },

                        {
                            name: this.$t('contract.new.header'),
                            path: '',
                            type: 'active',
                        }

                    ],
                    property: null,
                    jrs: false,
                    children: false
                },

                vars: {
                    headers: [],
                    headers_extras: [],
                    property: null,
                    jrs: false,
                    children: false,
                    base_cost: null,
                    base_cost_active: false,

                    rowLink: false,
                    rateplanLink: false,
                    rateConfigsBase: [],
                    rateConfigs: [],
                    allInclusive: false,
                    roomsLinks: false,
                    minCapacity: 2,
                    roomLinkBase: {},

                    payments_status: false,
                    payments: [],
                    meal_plans: [],

                    rateplansBD: [],
                    rateconfigsOriginal: [],
                    rateconfigsBD: [],
                    roomsDB: [],
                    roomsBase: [],
                    costRateconfigs: [],
                    rateconfigsLinksBD: [],


                    rateplans: [{
                        _id: '',
                        name: 'Seleccionar'
                    }],
                    rooms: [{
                        _id: '',
                        max_capacity: 0,
                        name: 'Seleccionar'
                    }],
                    annulments: [{
                        _id: '',
                        name: 'Seleccionar'
                    }],


                },
                modelBase: {
                    _id: '',
                    base_rate: '',
                    base_pax: null,
                    //base_link: null,
                    extra_pax: '',
                    extra_pax_link: null,
                    children_price: null,
                    children_link_type: null,
                    jrs_price: null,
                    jr_link_type: null,
                    isBaseRate: false,
                    cancellation_policy: null,

                    jrs_status: false,
                    children_status: false,
                    room_base: false,
                    max_capacity: 0,
                    max_adults: 2,

                    room_link: null,
                    room: '',
                    room_link_type: null,
                    room_link_type_pax: null,
                    assignments: [],

                },
                generalInfo: {
                    types: [],
                    payment: {
                        pay_hotel: false,
                        pay_paypal: false,
                        pay_tdc: false
                    },
                    info_links: {
                        title: {
                            normal: ['Tarifa Base', 'Extra Pax'],
                            extendible: ['Sencilla', 'Doble', 'Triple', 'Cuadruple', 'Quintuple', 'Sextuple', 'Septima', 'Octava', 'Novena', 'Decima']
                        }
                    },
                    format_payment: [
                        {
                            _id: '+$',
                            name: '( + ) $',
                            value: '1',
                            type: '$'

                        },
                        {
                            _id: '-$',
                            name: '( - ) $',
                            value: '-1',
                            type: '$'

                        },
                        {
                            _id: '+%',
                            name: '( + ) %',
                            value: '1',
                            type: '%'
                        },
                        {
                            _id: '-%',
                            name: '( - ) %',
                            value: '-1',
                            type: '%'
                        },
                        {
                            _id: '=',
                            name: '=',
                            value: '0',
                            type: '='
                        }
                    ],
                    currencys: [
                        {
                            _id: 'MXN',
                            name: 'MXN',

                        },
                        {
                            _id: 'USD',
                            name: 'USD',

                        },
                        /*{
                            _id: 'PTS',
                            name: 'PUNTOS',

                        }*/

                    ],
                },
                rateplan: {
                    _id: '',
                    comission:{
                        stripe: null,
                    },
                    property_id: null,
                    inclusiveAll: false,
                    roomLinked: false,
                    contract_base: true,
                    name: '',
                    cod: '',
                    meal_plan: '',
                    currency: '',
                    is_public: false,
                    cancellation_policy: '',
                    status: true,
                    infoRooms: [],
                    payment_options: [],
                    linked_rateplans : [],
                    rates_list: [],
                    vigency: [],
                    rateplan_link: {
                        rateplan: '',
                        type: '',
                        value: '',
                    },
                },
                errores: {
                    rates: [false],
                    payments: false,
                    racks: false,
                    rooms: false,
                    roomsLinked: false,
                    roomsText: '',
                    roomsLinkedText: '',
                    linksType: false,
                    linksValue: false,
                    vigencyText: false,
                    comissionType: false
                },
                modelValidations: {
                    name: {
                        required: true
                    },
                    meal_plan: {
                        required: true
                    },
                    code: {
                        required: true
                    },
                    currency: {
                        required: true
                    },
                    room_id: {
                        required: true
                    },
                    base_rate: {
                        required: true
                    },
                    iva: {
                        required: true
                    },
                    politics:{
                        required: true
                    },
                },


                pickerOptions1: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate() - 1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    }

                },

                pickerOptions2: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate() - 1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    }


                },

            }
        },

        mounted() {
            this.$refs.topProgress.start();
        },
        created() {
            this.initPage(JSON.parse(localStorage.getItem('property'))._id);
        },
        methods: {


            /*-----------------------------Inicio-----------------------------*/

            async initPage(property_id) {
                await this.getInfoProperty(property_id);
                await this.configuracionGeneral(property_id);
                this.configLogs(property_id, this.info_page.section_name);
                this.checkRoute();
                this.checkRowLink();
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            async getInfoProperty(property_id) {

                let meal_plans = [],
                    payments = [],
                    payments_status = false;

                let propertyResponse = await propertyService.getPropertyId(property_id);
                propertyResponse = propertyResponse.data.data.property;
                //console.log('propertyResponse', propertyResponse);

                this.vars.property = propertyResponse;

                /****************Verificamos si es solo adultos**************/
                if (!propertyResponse.only_adults && propertyResponse.age_jr) {
                    this.vars.jrs = true;
                    this.generalInfo.info_links.title.extendible.push('Juniors');
                }
                if (!propertyResponse.only_adults && propertyResponse.age_child) {
                    this.info_page.children = true;
                    this.generalInfo.info_links.title.extendible.push('Niños');
                }
                /************************Metodos de pagos**********************/
                if (propertyResponse.pay_tdc) {
                    payments.push({
                        _id: 'TDD',
                        name: 'Tarjeta de crédito/debito (OPENPAY)',
                        status: true
                    })
                    payments_status = true;
                }
                if (propertyResponse.pay_paypal) {
                    payments.push({
                        _id: 'PAYPAL',
                        name: 'PayPal',
                        status: true
                    })
                    payments_status = true;
                }
                if (propertyResponse.pay_hotel) {
                    payments.push({
                        _id: 'ARRIVE',
                        name: 'Pago en destino',
                        status: true
                    })
                    payments_status = true;
                }
                if (propertyResponse.pay_validate) {
                    payments.push({
                        _id: 'VALIDATECARD',
                        name: 'Verificación de tarjeta',
                        status: true
                    })
                    payments_status = true;
                }

                if (propertyResponse.pay_firstdata) {
                    payments.push({
                        _id: 'FIRSTDATA',
                        name: 'Tarjeta de crédito/debito (FIRSTDATA)',
                        status: true
                    })
                    payments_status = true;
                }

                if (propertyResponse.pay_stripe) {
                    payments.push({
                        _id: 'STRIPE',
                        name: 'Tarjeta de crédito/debito (STRIPE)',
                        status: true
                    })
                    payments_status = true;
                }

                payments_status = true;

                /****************Tipos de tarifas**************/
                if (propertyResponse.plan_ep) {
                    meal_plans.push({
                        _id: 1,
                        name: 'Plan Europeo',
                        status: true
                    })
                }
                if (propertyResponse.plan_epb) {
                    meal_plans.push({
                        _id: 2,
                        name: 'Plan Europeo + Desayuno',
                        status: true
                    })
                }
                if (propertyResponse.plan_ai) {
                    meal_plans.push({
                        _id: 3,
                        name: 'Todo Incluido',
                        status: true
                    })
                }

                /*-----------Acutualizacion de datas-----------*/
                this.vars.meal_plans = meal_plans;
                this.vars.payments_status = payments_status;
                this.vars.payments = payments;
                /*---------------------------------------------*/
            },

            checkRowLink() {
                let rateplans = [...this.vars.rateplansBD];
                let rateplan_id = this.rateplan._id;
                let indexKey = rateplans.findIndex(item => item._id === rateplan_id)
                //console.log('indexKey', indexKey, rateplans);
                if (indexKey > 0) {
                    rateplans = rateplans.splice(indexKey, 0)
                }
                if (rateplans.length) {
                    this.vars.rowLink = true;
                }

            },

            async configuracionGeneral(property_id) {
                let rateplans = [],
                    annulments = [],
                    rooms = [];
                this.rateplan.property_id = property_id;

                /****************Contratos bases**************/


                 let   params = {
                        property_id: property_id,
                        status: true,
                        expired: false
                    }





                let rateplansResponse = await contractService.getRateplanIndex(params);
                rateplansResponse = rateplansResponse.data.data.rateplans;

                //console.log('rateplansResponse', rateplansResponse)
                rateplans.push({
                    _id: '',
                    name: 'Seleccionar',
                    status: true,
                    fixed: true
                });
                rateplansResponse.forEach(elem => {
                    if (!elem.rateplan_link) {
                        elem.fixed = false;
                        rateplans.push(elem)
                    }
                })

                /****************Politicas de cancelacion**************/

                let annulmentsResponse = await annulmentService.getAnnulments('true', property_id)
                annulmentsResponse = annulmentsResponse.data.data.cancellationPolicys;
                annulments.push({
                    _id: '',
                    name: 'Seleccionar'
                });
                annulments = annulments.concat(annulmentsResponse);
                /****************Habitaciones**************/
                let roomsResponse = await roomService.getRooms('true', property_id)
                roomsResponse = roomsResponse.data.data.rooms;
                rooms.push({
                    _id: '',
                    name: 'Seleccionar'
                });
                rooms = rooms.concat(roomsResponse);
                this.vars.annulments = annulments;
                this.vars.rooms = rooms;
                this.vars.roomsDB = rooms;
                this.vars.rateplans = rateplans;
                this.vars.rateplansBD = rateplans;

                if (this.vars.rateplanLink && this.info_page.action === 'edit') {
                    statusRow = true;
                }

                //console.log('generalInfo', this.generalInfo)
                this.$refs.topProgress.done()
            },


            async contractType(isUpdate = false) {
                let type_contract = this.rateplan.rateplan_link;
                let contracts = [...this.vars.rateplans];
                this.vars.allInclusive = (this.rateplan.meal_plan === 3);
                //console.log('type_contract', type_contract)

                if (!type_contract.rateplan || type_contract.rateplan === '') {
                    this.rateplan.contract_base = true;
                    this.vars.rateplanLink = false;
                    this.rateplan.currency = '';
                    this.rateplan.meal_plan = '';
                    this.rateplan.link_type = '';
                    this.rateplan.link_value = '';
                    this.rateplan.infoRooms = [];
                    this.pickerOptions2 = {};
                } else {
                    let contract_info = contracts.find(item => item._id === type_contract.rateplan)
                    this.rateplan.currency = contract_info.currency;
                    this.rateplan.meal_plan = contract_info.meal_plan;
                    this.vars.rateplanLink = true;
                    this.rateplan.contract_base = false;
                    if (!isUpdate) {
                        this.rateplan.infoRooms = [];
                    }

                    if (contract_info.from && contract_info.from !== null && contract_info.to !== null) {
                        let minOriginalDate = moment(contract_info.from, 'YYYY-MM-DD');
                        let maxOriginalDate = moment(contract_info.to, 'YYYY-MM-DD');

                        if (this.rateplan.vigency !== null && this.rateplan.vigency.length === 2) {
                            if (moment(this.rateplan.vigency[0]).isBefore(minOriginalDate, 'day') ||
                                moment(this.rateplan.vigency[1]).isAfter(maxOriginalDate, 'day')) {
                                this.rateplan.vigency = [];
                            }
                        }

                        this.pickerOptions2 = {
                            disabledDate(time) {
                                let minDate = moment(contract_info.from, 'YYYY-MM-DD');
                                let maxDate = moment(contract_info.to, 'YYYY-MM-DD');
                                //console.log('maxDate', maxDate, contract_info.to)
                                return (time.getTime() < minDate || time.getTime() > maxDate);
                            }
                        };
                    } else {
                        //console.log('buscando disabled');

                        this.pickerOptions2 = {
                            disabledDate(time) {
                                let minDate = moment.now();
                                //console.log('minDate', minDate)
                                return (time.getTime() < minDate);
                            }

                        }
                    }


                    this.checkType();
                    this.chargeRateConfigBase(contract_info, isUpdate)
                }

                //console.log('this.rateplan', this.rateplan, this.vars.rateplanLink)
            },
            updatesRoomsAvailables(rc_response) {
                //let rc_response = this.vars.rateConfigsBase
                let newRooms = []
                newRooms.push({
                    _id: '',
                    name: 'Seleccionar'
                });
                let roomsDB = this.vars.roomsDB;
                rc_response.forEach(rateBase => {
                    let responseRoom = roomsDB.find(roomItem => roomItem._id === rateBase.room)
                    if (responseRoom) {
                        newRooms.push(responseRoom);
                    }
                });
                //console.log('newRooms', newRooms);
                this.vars.rooms = newRooms;
            },
            checkRoute() {
                let contract_id = this.$route.params.id;

                if (this.$route.name === 'editContract') {
                    //let index_ky =this.findIndex(contracts, contract_id);
                    //contracts.splice(index_ky, 1);
                    this.log_page.action = 1;
                    this.info_page.action = 'edit';
                    this.info_page.header = this.$t('contract.edit.header');
                    this.info_page.button = this.$t('form.update');
                    this.info_page.breadcrumbs[this.info_page.breadcrumbs.length - 1].name = this.$t('contract.edit.header');
                    this.updateData(contract_id);
                }

            },


            async chargeRateConfigBase(rateplan_info, isUpdate) {
                let rateconfigs = [];
                let rc_response = await rateplanService.getRateConfig({rateplan: rateplan_info._id});
                rc_response = rc_response.data.data.rateconfigs;

                this.determineBaseConfig(rc_response);
                this.updatesRoomsAvailables(rc_response);

                //console.log('infoRooms', isUpdate, rc_response);

                let roomsLinks = false;
                let property = this.vars.property;

                rc_response.forEach(rateBase => {

                    let dataRoom = this.vars.roomsDB.find(room => room._id === rateBase.room)
                    rateBase.jrs_status = this.showJrs(property, dataRoom, 'general');
                    rateBase.children_status = this.showChilds(property, dataRoom, 'general');

                    if (rateBase.room_link_type) {
                        roomsLinks = true;
                    } else {
                        //console.log('base_cost', , this.vars.base_cost);
                        this.vars.base_cost = rateBase.base_rate;
                        this.vars.base_cost_active = true;
                    }
                    if (rateBase.room_link_type === null || rateBase.room_link_type === "0") {
                        rateBase.isBaseRate = true;
                    }
                    if (this.rateplan.meal_plan === 3) {
                        rateBase.assignments = this.constructRateConfing(rateBase);
                        //console.log('existSecond', existSecond);
                        /* item.assignments.forEach(assigItem=>{
                         })*/
                    }else{


                        //console.log('rateBase', rateBase);
                        if(rateBase.base_pax===undefined){
                            rateBase.base_pax = 2;
                        }
                    }

                    if (isUpdate) {
                        let idxRoom = this.rateplan.infoRooms.findIndex(room => room.room === rateBase.room);
                        if (idxRoom >= 0) {
                            rateBase._id = this.rateplan.infoRooms[idxRoom]._id;
                            rateBase.rateplan = this.rateplan.infoRooms[idxRoom].rateplan;
                            this.rateplan.infoRooms[idxRoom] = rateBase;
                        }
                    } else {
                        rateBase._id = '';
                        this.rateplan.infoRooms.push(rateBase);
                    }
                })
                this.vars.roomsLinks = roomsLinks;
                this.determineHeaders();
                this.determineHeadersExtra();
                //console.log(rc_response, 'rc_response');
                this.vars.rateConfigsBase = rc_response;

            },
            async updateData(rateplan_id) {
                let data = await rateplanService.getRateplanId(rateplan_id)
                this.generalInfo.rates_list = data.rates_list;
                data = data.data.data.rateplan;
                    this.info_page.original_info = JSON.parse(JSON.stringify(data))
                //console.log('data', data);
                if (data.from !== null) {


                    data.from = moment(data.from, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
                    data.to = moment(data.to, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')

                    data.vigency = [data.from, data.to]
                    delete data.from;
                    delete data.to;
                }


                for (let prop in data) {
                    this.rateplan[prop] = data[prop];
                }
                let rateplan = this.rateplan
                let rateplanLink = false;
                let rateConfigsBase = [];
                let rateConfigs = [];
                let allInclusive = false;
                let roomsLinks = false;
                let minCapacity = 2;
                let rateConfigLink = [];

                let rateconfigBase = await rateplanService.getRateConfig({rateplan: rateplan_id})

                this.vars.rateconfigsOriginal = JSON.parse(JSON.stringify(rateconfigBase.data.data.rateconfigs));
                rateconfigBase = rateconfigBase.data.data.rateconfigs;



                //console.log('rateconfigBase', rateconfigBase);
                let property = this.vars.property;
                rateconfigBase.forEach((item, idx) => {

                    let dataRoom = this.vars.roomsDB.find(room => room._id === item.room)
                    item.jrs_status = this.showJrs(property, dataRoom, 'general');
                    item.children_status = this.showChilds(property, dataRoom, 'general');

                    if (idx === 0) {
                        item.isBaseRate = true;
                    }
                    if (item.room_link_type) {
                        roomsLinks = true;
                    }
                    if ((item.room_link_type === undefined || item.room_link_type === null || item.room_link_type === "0") && idx === 0) {
                        item.isBaseRate = true;
                    }
                    if (rateplan.meal_plan === 3) {
                        item.assignments = this.constructRateConfing(item);
                    }else{
                        item.max_capacity = dataRoom.max_adults;
                        if(item.base_pax===undefined){
                            item.base_pax = 2;
                        }
                    }
                })
                this.vars.allInclusive = (rateplan.meal_plan === 3);
                this.vars.roomsLinks = roomsLinks;
                this.rateplan.infoRooms = rateconfigBase;

                this.vars.rateconfigsBD = [...rateconfigBase];

                let arrayConfigsLinks = [];
                //console.log('rateplan.linked_rateplans', rateplan);
                if(rateplan.linked_rateplans.length){
                    let i = 0;
                    while (rateplan.linked_rateplans.length>i){
                        let linkRateplan = rateplan.linked_rateplans[i];
                        //console.log('linkRateplan', linkRateplan);
                        let rateconfigLinks = await rateplanService.getRateConfig({rateplan: linkRateplan._id})
                        rateconfigLinks = rateconfigLinks.data.data.rateconfigs;
                        //console.log('rateconfigLinks', rateconfigLinks);
                        arrayConfigsLinks = arrayConfigsLinks.concat(rateconfigLinks)
                        i++;
                    }
                }

                //console.log('arrayConfigsLinks', arrayConfigsLinks);

                this.vars.rateconfigsLinksBD = arrayConfigsLinks;
                this.determineHeaders();
                this.determineHeadersExtra();

                if (rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan !== '') {
                    //this.vars.base_cost_active = true;
                    //console.log('this.rateplan.infoRooms', this.rateplan.infoRooms);
                    this.contractType(true);
                }
            },

            alertMsg(){
                let htmlList = ''
                let rateplans = this.vars.rateplansBD;
                //console.log();

                htmlList += '<p>Es necesario completar las tarifas del contrato base antes de continuar:</p>'
                htmlList += '<div class="text-left box-info-rateplans">'
                let checkInfo = rateplans.find(rtplan => rtplan._id === this.rateplan.rateplan_link.rateplan)
                //console.log('checkinfo', checkInfo, this.rateplan.rateplan_link.rateplan);
                if (checkInfo) {
                    htmlList += checkInfo.name;
                }

                htmlList += '</div>'

                swal({
                    title: 'Advertencia',
                    type: 'warning',
                    html: htmlList,
                    //text: 'Es necesario completar las tarifas del contrato base antes de continuar',
                    confirmButtonText: 'Modificar contrato',
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill'
                }).then(() => {
                    this.$router.push({
                        name: 'editContract',
                        params: {
                            id: this.rateplan.rateplan_link.rateplan
                        }
                    })

                    //this.initPage(JSON.parse(localStorage.getItem('property'))._id);
                });


                //console.log('costVinculate', room_id, idIs, this.rateplan.rateplan_link.rateplan);
            },
            costVinculate(room_id, idIs) {
                let costIs = 0;
                if (this.rateplan.rateplan_link.rateplan !== '') {
                    //console.log('test', this.vars.costRateconfigs);

                    //console.log('costVinculate', room_id, idIs);
                    let infoRoom = this.vars.costRateconfigs.find(room => room.room_id === room_id);
                    if (infoRoom) {
                        //console.log('infoRoom', infoRoom, 'costVinculate', infoPrice,  idIs);
                        let infoPrice = infoRoom.costs.find(cost => cost.id === idIs);

                        //console.log('costVinculate', infoPrice);
                        try {
                            costIs = rateplanFuntions.gapFunction(infoPrice.value, this.rateplan.rateplan_link.value, this.rateplan.rateplan_link.type)

                        } catch (e) {
                            //console.log('e)', e);
                            this.alertMsg();
                        }
                    }
                    /*baseCost =*/

                    return '$ ' + rateplanFuntions.formatMoney(costIs);
                }

            },

            determineBaseConfig(rateconfigBase) {
                let newRateconfigs = [];
                let costBase = rateconfigBase[0].base_rate;
                rateconfigBase.forEach(item => {

                    let arrayCost = [];
                    let newcostBase = costBase;

                    if (item.room_link) {
                        item.base_rate = rateplanFuntions.gapFunction(newcostBase, item.base_rate, item.room_link_type)
                    }
                    if (item.base_link) {
                        item.base_rate = rateplanFuntions.gapFunction(costBase, item.base_rate, item.base_link)
                    }
                    arrayCost.push(this.insertElement('base_rate', item.base_rate))
                    if (item.extra_pax) {

                        //console.log('item.extra_pax', item.extra_pax);
                        if (item.extra_pax_link) {
                            item.extra_pax = rateplanFuntions.gapFunction(costBase, item.extra_pax, item.extra_pax_link)
                        }
                        if (item.room_link_type_pax) {
                            //console.log('item.room_link_type_pax', item.room_link_type_pax);
                            item.extra_pax = rateplanFuntions.gapFunction(item.base_rate, item.extra_pax, item.room_link_type_pax)
                            //console.log('value extra_pax', item.extra_pax);
                        }
                        arrayCost.push(this.insertElement('extra_pax', item.extra_pax))
                    }
                    if (item.children_price) {
                        if (item.children_link_type) {
                            item.children_price = rateplanFuntions.gapFunction(item.base_rate, item.children_price, item.children_link_type)
                        }
                        arrayCost.push(this.insertElement('children', item.children_price))
                    }

                    if (item.jrs_price) {
                        if (item.jr_link_type) {
                            item.jrs_price = rateplanFuntions.gapFunction(item.base_rate, item.jrs_price, item.jr_link_type)
                        }
                        arrayCost.push(this.insertElement('jrs', item.jrs_price))
                    }
                    if (item.assignments.length) {
                        item.assignments.forEach(assig => {
                            //console.log('assig', assig);
                            if (assig.link) {
                                assig.value = rateplanFuntions.gapFunction(item.base_rate, assig.value, assig.link)
                            }
                            arrayCost.push(this.insertElement(assig.cant, assig.value))
                        });

                    }
                    newRateconfigs.push({
                        room_id: item.room,
                        costs: arrayCost
                    });

                    //console.log('newRateconfigs', newRateconfigs);
                })

                this.vars.costRateconfigs = newRateconfigs;

            },
            insertElement(id, value) {
                return {
                    id: id,
                    value: value
                }
            },
            constructRateConfing(rateChoose) {
                //console.log('rooms', this.vars.roomsDB);
                let dataRoom = this.vars.roomsDB.find(item => item._id === rateChoose.room)
                let assignments = [];
                let existSecond = rateChoose.assignments.find(element => element.cant === 2)
                if (existSecond === undefined) {
                    this.insertAt(rateChoose.assignments, 1, {
                        _id: '',
                        cant: 2,
                        value: rateChoose.base_rate,
                        link: rateChoose.room_link_type
                    })
                    //console.log('NO: existSecond', existSecond);
                }


                //console.log('rateChoose.assignments', rateChoose.assignments);
                for (let i = 0; i < dataRoom.max_adults; i++) {

                    if (rateChoose.assignments[i]) {
                        assignments.push(rateChoose.assignments[i]);
                    } else {
                        assignments.push({
                            _id: null,
                            cant: i+1,
                            value: '',
                            link: ''
                        })
                    }
                }


                return assignments;
            },

            insertAt(array, index, elementsArray) {
                array.splice(index, 0, elementsArray);
            },

            async saveItem() {

                let rateplan = this.rateplan;

                if (rateplan.vigency.length === 2) {
                    rateplan.from = rateplan.vigency[0] + ' 00:00:00';
                    rateplan.to = rateplan.vigency[1] + ' 23:59:59';
                }


                let racks = rateplan.rates_list;
                //console.log('racks', racks)
                /* rateplan.status = (rateplan.cancellation_policy!=='');
                 this.generalInfo.roomsArrays = [];

                 let infoRooms = rateplan.infoRooms;
                 infoRooms.forEach(roomItem=>{
                     this.generalInfo.roomsArrays.push(roomItem.room)
                 })*/

                rateplanService.createRatePlan(rateplan)
                    .then(async (response) => {
                        let responseRateplan = response.data.data.rateplan;
                        rateplan._id = responseRateplan._id;
                        await this.updateRateConfigs(rateplan);
                        //console.log('De igual forma guarda aun con error')

                        let paramsLog= this.log_page;
                        paramsLog.object_id = rateplan._id;
                        paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                        paramsLog.action = 0;
                        paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                        await logService.create(paramsLog);

                        this.showSwal(this.$t('contract.new.msg'))
                    })
                    .catch(error => {
                        if (error.response && error.response.data.err === "RatePlan validation failed: cod: cod debe ser único") {
                            this.alertError();
                        }
                    })
            },

            async updateItem() {

                let rateplan = this.rateplan;
                //console.log('rateplan', rateplan)
                let racks = rateplan.rates_list;

                if (rateplan.vigency.length === 2) {

                    rateplan.from = moment(rateplan.vigency[0]).format('YYYY-MM-DD')
                    rateplan.to = moment(rateplan.vigency[1]).format('YYYY-MM-DD')
                    rateplan.from = rateplan.from + ' 00:00:00'
                    rateplan.to = rateplan.to + ' 23:59:59'
                }


                rateplan.status = (rateplan.cancellation_policy !== '' && rateplan.status);


                let infoRooms = rateplan.infoRooms;
                let roomsArrays = this.vars.rateconfigsBD;
                let roomDeletes = [];


                roomsArrays.forEach(roomItem => {
                    let findElement = infoRooms.find(itemIs => itemIs._id === roomItem._id);
                    if (findElement === undefined) {
                        roomDeletes.push(roomItem._id)
                    }

                })
                await this.deleteRateConfigs(roomDeletes);

                rateplanService.updateRatePlan(rateplan)
                    .then(async (response) => {

                        //console.log('De igual forma actualiza aun con error', response)
                        let responseRateplan = response.data.data.rateplan;

                        let changes = logFunctions.cheackProp(this.info_page.original_info, responseRateplan, this.info_page.section_name)
                        if(changes.length){
                            let paramsLog= this.log_page;
                            paramsLog.object_id = responseRateplan._id;
                            paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                            paramsLog.action = 1;
                            paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                            paramsLog.data = changes;
                            await logService.create(paramsLog);
                        }

                        rateplan._id = responseRateplan._id;
                        await this.updateRateConfigs(rateplan);
                        this.showSwal(this.$t('contract.new.msg'))
                    })
                    .catch(error => {
                        if (error.response && error.response.data.err === "Validation failed: cod: cod debe ser único") {
                            this.alertError();
                        }
                    })
            },

            /*----------------Configuraciones Tipos de contratos----------------*/

            async deleteRateConfigs(rateConfigs) {
                let totalItems = rateConfigs.length
                let idx = 0;
                while (idx < totalItems) {
                    let item_id = rateConfigs[idx]
                    await rateplanService.deleteRateConfig(item_id);
                    idx++;
                }
                return true;
            },


            async updateRateConfigs(rateplan) {
                let rateConfigs = this.rateplan.infoRooms;
                let totalItems = rateConfigs.length
                let idx = 0;
                let checkRoom = true;
                let paramsLog= {...this.log_page};
                paramsLog.section = logFunctions.findSection('rate')._id;
                //console.log('totalItems', totalItems);
                while (idx < totalItems) {
                    let auxObject = rateConfigs[idx]


                    auxObject.rateplan = rateplan._id;
                    auxObject.cancellation_policy = rateplan.cancellation_policy;
                    //console.log('auxObject', idx, auxObject);
                    if (rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan !== '') {
                        delete auxObject.base_rate;
                        delete auxObject.extra_pax;
                        delete auxObject.assignments;
                        delete auxObject.jrs_price;
                        delete auxObject.children_price;
                        delete auxObject.room_link;
                        delete auxObject.room_link_type;
                        delete auxObject.room_link_type_pax;
                        delete auxObject.jr_link_type;
                        delete auxObject.isBaseRate;
                        delete auxObject.jrs_status;
                        delete auxObject.children_link_type;
                        delete auxObject.children_status;
                        checkRoom = false;
                        //delete auxObject.assignments;
                    }
                    if (rateplan.meal_plan === 3 && checkRoom) {
                        let existSecond = auxObject.assignments.findIndex(element => element.cant === 2)
                        if (existSecond > 0) {
                            auxObject.assignments.splice(1, existSecond)
                        }
                        auxObject.base_pax = null;
                    }

                    if (this.vars.roomsLinks) {
                        if (rateplan.meal_plan === 3) {
                            auxObject.room_link_type_pax = null;
                            auxObject.extra_pax = null;
                        } else {
                            auxObject.children_price = null;
                            auxObject.children_link_type = null;
                            auxObject.jrs_price = null;
                            auxObject.jr_link_type = null;
                            auxObject.assignments = [];

                        }
                        if (auxObject.room_link === null || auxObject.room_link === undefined) {
                            auxObject.room_link_type = null;
                            auxObject.room_link = null;
                        }
                    } else {
                        auxObject.room_link_type = null;
                        auxObject.room_link = null;
                        //auxObject.room_link_type_pax= null;
                    }

                    //console.log('auxObject', auxObject);
                    paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                    if (auxObject._id === '') {
                        paramsLog.action = 0;
                        paramsLog.section = 4
                        //check:RATEUPDATE
                        let responseConfig = await rateplanService.createRateConfig(auxObject);
                        responseConfig = responseConfig.data.data.rateconfig;

                        paramsLog.object_id = responseConfig._id;
                        paramsLog.data = [];
                        await logService.create(paramsLog);
                    } else {
                        paramsLog.action = 1;
                        paramsLog.object_id = auxObject._id;
                        paramsLog.section = 4
                        let response =  await rateplanService.updateRateConfig(auxObject);
                        response = response.data.data.rateconfig;
                        //console.log(response, 'response');
                        let infoRateConfig = this.vars.rateconfigsOriginal.find(item=> item._id === auxObject._id);
                        //console.log(infoRateConfig, 'infoRateConfig');

                        let changes = logFunctions.cheackProp(infoRateConfig, response, 'rate')
                        if(changes.length){
                            paramsLog.object_id = response._id;
                            paramsLog.section = 4
                            paramsLog.action = 1;
                            paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                            paramsLog.data = changes;
                            await logService.create(paramsLog);
                        }
                    }

                    idx++;
                }
                return true;
            },


            checkType() {
                let type_is = this.rateplan.meal_plan;
                this.vars.allInclusive = (type_is === 3);
                this.rateplan.inclusiveAll = (type_is === 3);
                this.filterRateplans();
                this.determineHeaders();
                this.determineHeadersExtra();
                this.updatesRoomsSelected();

            },

            updatesRoomsSelected() {
                let roomsSelected = this.rateplan.infoRooms;
                let allInclusive = this.vars.allInclusive;
                if (allInclusive) {
                    roomsSelected.forEach((item, n) => {
                        let assignments = [];

                        if (this.vars.roomsLinks) {
                            item.isBaseRate = (n === 0)
                        }

                        if (allInclusive) {

                            for (let i = 1; i <= item.max_adults; i++) {
                                let modelassignment = {
                                    _id: null,
                                    cant: i,
                                    value: ''
                                }

                                assignments.push(modelassignment);
                            }
                            this.determineHeadersExtra();
                        }

                        item.assignments = assignments;
                    })
                }


            },
            filterRateplans() {
                let rateplan = this.rateplan;
                let currency = rateplan.currency;
                let mealPlan = rateplan.meal_plan;
                let rateplansAll = this.vars.rateplansBD;
                let arrayAux = [];
                let rateplansAux = [{
                    id: '',
                    name: 'Seleccionar'
                }];


                if (currency !== '' && mealPlan !== '') {
                    arrayAux = rateplansAll.filter(item => item.meal_plan === mealPlan && item.currency === currency)
                } else if (mealPlan !== '' && currency === '') {
                    arrayAux = rateplansAll.filter(item => item.meal_plan === mealPlan)
                } else if (mealPlan === '' && currency !== '') {
                    arrayAux = rateplansAll.filter(item.currency === currency)
                } else if (currency === '' && mealPlan === '') {
                    arrayAux = rateplansAll;
                }
                arrayAux = arrayAux.filter(item => item._id !== rateplan._id)

                rateplansAux = rateplansAux.concat(arrayAux)
                this.vars.rateplans = rateplansAux;
            },
            contractMealplan(meal_plan) {
                let meal_plans = this.vars.meal_plans;
                let valueT = '';
                //console.log('meal_plan', meal_plan)
                if (meal_plan && meal_plan !== '' && meal_plans.length) {
                    let contract_info = meal_plans.find(item => item._id === meal_plan)
                    valueT = contract_info.name;
                }
                return valueT;
            },
            gapFunctionView(base_rate, value, link) {
                let value_return = rateplanFuntions.gapFunction(base_rate, value, link);
                return '$ ' + rateplanFuntions.formatMoney(value_return);
            },
            moneyView(value) {
                return '$ ' + rateplanFuntions.formatMoney(value);
            },

            checkDifference(n = null, type = null) {

                let selected_rooms = this.rateplan.infoRooms;
                let full_rooms = this.vars.rooms;

                full_rooms.forEach(item => {

                    item.status = true;
                });
                let contract_id = this.rateplan.link_rateplan

                selected_rooms.forEach(item => {

                    if (type === 'change' && contract_id !== '') {

                        //this.ContractInfo();
                    }
                    //console.log('encontrados',full_rooms.findIndex(room => room._id === item.room_id))
                    let idx = full_rooms.findIndex(room => room._id === item.room);
                    if (idx >= 0) {
                        full_rooms[idx].status = false;
                    }
                })
            },


            /*------------------------------Validaciones------------------------*/
            getError(fieldName) {
                return this.errors.first(fieldName);
            },
            validate(action) {
                //console.log('action?', action, this.customValidity())
                this.customValidity();
                this.$validator.validateAll().then(isValid => {
                    //console.log('isValid?', isValid)
                    if (isValid) {
                        if (this.customValidity()) {
                            //console.log('validado enviando form');
                            if (action === 'add') {
                                this.saveItem();
                            } else {
                                this.updateItem();
                            }
                        }

                    }
                })
            },

            customValidity() {
                let error_response = true;


                let rooms = this.rateplan.infoRooms;
                let checkRooms = true;


                let payments = this.rateplan.payment_options;
                let errores = this.errores;

                errores.roomsText = '';
                errores.racks = false;

                errores.linksType = false;
                errores.linksValue = false;
                errores.vigencyText = false;
                errores.comissionType = false;

                if (rooms.length === 0) {
                    errores.rooms = false;
                    errores.roomsText = this.$t('form.error.room.min')
                    error_response = false;
                }


                let rateplan = this.rateplan;
                let racks = this.rateplan.rates_list;
                //console.log('rateplan', rateplan);
                //console.log('rateplan', rateplan);

                if (rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan !== '') {
                    checkRooms = false;
                    if (rateplanFuntions.validadorCustom([rateplan.rateplan_link.type], 'string')) {
                        error_response = false;
                        errores.linksType = true;
                    }
                    if (rateplanFuntions.validadorCustom([rateplan.rateplan_link.value], 'number')) {
                        error_response = false;
                        errores.linksValue = true;
                    }
                }

                if (rateplan.comission!==undefined) {                   
                    for (const [key, value] of Object.entries(rateplan.comission)) {
                            console.log('value', value);
                        if(value && value.trim().length > 0 && rateplanFuntions.validadorCustom([value], 'number')){
                            console.log('if', rateplanFuntions.validadorCustom([value], 'number'));
                            error_response = false;
                            errores.comissionType = true;
                        }                
                    }                                  
                }

              

                /*let contract_info = this.vars.rateplans.find(item => item._id === rateplan.rateplan_link.rateplan);
                console.log('contract_info', contract_info);
                if(contract_info!==undefined){

                    if(contract_info.from && contract_info.from !== null && contract_info.to!==null){
                        if(rateplan.vigency.length<2  || rateplan.vigency[0]===null || rateplan.vigency[1]===null){
                            error_response = false;
                            errores.vigencyText = true;
                        }
                    }
                }*/

                if (rateplan.vigency === null || rateplan.vigency.length < 2 || rateplan.vigency[0] === null || rateplan.vigency[1] === null) {
                    error_response = false;
                    errores.vigencyText = true;
                }
                if (this.vars.roomsLinks) {
                    rooms[0].room_link_type = '=';
                }


                if (checkRooms) {

                    rooms.forEach((room, idx) => {
                        //console.log('room', room)

                        if (this.vars.allInclusive) {

                            if (rateplanFuntions.validadorCustom([room.room], 'string')) {
                                error_response = false;
                                errores.roomsText = this.$t('form.error.room.required')
                            } else {
                                let arrayValidate = [];
                                let arrayValidateLinks = [];
                                room.assignments.forEach(assig => {
                                    arrayValidate.push(assig.value)
                                    if (this.vars.roomsLinks) {
                                        arrayValidateLinks.push(assig.link)
                                    }

                                })
                                arrayValidate[1] = room.base_rate;
                                if (this.vars.roomsLinks) {
                                    arrayValidateLinks[1] = room.room_link_type;
                                }
                                if (room.jrs_status) {
                                    arrayValidate.push(room.jrs_price);
                                    if (this.vars.roomsLinks) {
                                        arrayValidateLinks.push(room.jr_link_type)
                                    }
                                }
                                if (room.children_status) {
                                    arrayValidate.push(room.children_price);
                                    if (this.vars.roomsLinks) {
                                        arrayValidateLinks.push(room.children_link_type)
                                    }
                                }

                                if (rateplanFuntions.validadorCustom(arrayValidate, 'number')) {
                                    error_response = false;
                                    errores.roomsText = this.$t('form.error.room.number')
                                }
                                if (rateplanFuntions.validadorCustom(arrayValidate, 'string')) {
                                    error_response = false;
                                    errores.roomsText = this.$t('form.error.room.required')
                                }
                                if (this.vars.roomsLinks) {
                                    if (rateplanFuntions.validadorCustom(arrayValidateLinks, 'string')) {
                                        error_response = false;
                                        errores.roomsText = this.$t('form.error.room.required')
                                    }

                                }


                                /*let arrayValidate = [room.sencilla.value, room.double.value];
                                let arrayValidateLinks = [room.sencilla.link, room.double.link];
                                if(room.max_capacity>=3){
                                    arrayValidate.push(room.triple.value)
                                    arrayValidateLinks.push(room.triple.link)
                                }
                                if(room.max_capacity>=4){
                                    arrayValidate.push(room.cuadruple.value)
                                    arrayValidateLinks.push(room.cuadruple.link)
                                }
                                if(room.max_jr>0){
                                    arrayValidate.push(room.jrs_price)
                                    arrayValidateLinks.push(room.jr_link_type)
                                }
                                if(room.max_children>0){
                                    arrayValidate.push(room.children_price)
                                    arrayValidateLinks.push(room.children_link_type)
                                }
                                if(rateplanFuntions.validadorCustom(arrayValidate, 'number')){
                                    error_response = false;
                                    errores.roomsText = this.$t('form.error.room.number')
                                }
                                if(rateplanFuntions.validadorCustom(arrayValidate, 'string')){
                                    error_response = false;
                                    errores.roomsText = this.$t('form.error.room.required')
                                }
                                if(rateplan.roomLinked){
                                    if(rateplanFuntions.validadorCustom(arrayValidateLinks, 'string')){
                                        error_response = false;
                                        errores.roomsText = this.$t('form.error.room.required')
                                    }

                                }*/
                            }
                        } else {

                            let arrayValidate = [room.base_rate, room.extra_pax];
                            if (rateplanFuntions.validadorCustom(arrayValidate, 'number')) {
                                error_response = false;
                                errores.roomsText = this.$t('form.error.room.number')
                            }
                            arrayValidate.push(room.room);
                            if (rateplanFuntions.validadorCustom(arrayValidate, 'string')) {
                                error_response = false;
                                errores.roomsText = this.$t('form.error.room.required')
                            }

                            if (this.vars.roomsLinks) {
                                arrayValidate = [room.room_link_type, room.room_link_type_pax];
                                if (rateplanFuntions.validadorCustom(arrayValidate, 'string')) {
                                    error_response = false;
                                    errores.roomsText = this.$t('form.error.room.required')
                                }
                            }
                        }


                    });


                    racks.forEach((rack, idx) => {
                        if (rack.value === '') {
                            error_response = false;
                            errores.racks = true;
                        }
                    })
                }

                if (payments.length === 0) {
                    error_response = false;
                    errores.payments = true;

                }
                //console.log('verify validity', error_response, errores)

                //return false;
                return error_response;
            },
            sendForm(action) {
                this.validate(action);
                //this.saveItem();
            },
            addRoom() {
                /*if(this.alertLinkMsg()){
                    return;
                }*/


                let baseRateConfig = {...this.modelBase}
                //console.log('baseRateConfig', baseRateConfig)

                this.checkDifference(null, 'change');


                this.rateplan.infoRooms.push(baseRateConfig)
            },
            deleteRoom(n) {
                if(this.alertLinkMsg(n)){
                    return;
                }
                let baseCost = this.rateplan.infoRooms[0].base_rate;


                this.rateplan.infoRooms.splice(n, 1)
                if (this.rateplan.infoRooms.length) {

                    if(this.vars.roomsLinks && n === 0){
                        let newBase = this.rateplan.infoRooms[0];
                        this.rateplan.infoRooms[0].isBaseRate = true;
                        this.rateplan.infoRooms[0].base_rate =  rateplanFuntions.gapFunction(baseCost, newBase.base_rate, newBase.room_link_type);
                        this.rateplan.infoRooms[0].room_link = null;
                        this.rateplan.infoRooms[0].room_link_type = null;
                    }
                    if (this.rateplan.infoRooms[0].base_link) {

                        this.rateplan.infoRooms[0].base_link = '=';
                    }
                    if (this.rateplan.infoRooms[0].assignments.length >= 2 && this.rateplan.infoRooms[0].assignments[1].link) {
                        this.rateplan.infoRooms[0].assignments[1].link = '=';
                    }
                }
                this.checkDifference();
            },

            /*-----------------------------Guardado---------------------------*/

            /*------------------------------Acciones habitaciones---------*/

            /******************************************** RACKS **************************************************************/
            addRack() {
                this.rateplan.rates_list.push('');

            },
            assigNameRack(idxk) {
                let acomp = '';
                if (idxk === 0) {

                    acomp = 'Rack';
                } else if (idxk === 1) {
                    acomp = 'Bar';
                } else {
                    acomp = 'Bar ' + ((idxk));
                }
                return acomp;
            },
            deleteRack(key) {

                let array_aux = this.rateplan.rates_list;
                if (array_aux.length > 0) {
                    array_aux.splice(key, 1);
                }

                /*array_aux.forEach((item, idx)=>{
                    let acomp = '';
                    if(idx===0){
                        acomp = 'Rack';
                    }else if(idx>0){
                        acomp = 'Bar';
                        acomp+=(idx>1)?idx:'';
                    }

                    item.name = acomp;
                })*/
            },
            calculateVinculates(idx, type) {

                let linkRateplan = this.rateplan.rateplan_link;
                let infoRooms = this.rateplan.infoRooms;
                let baseCost = 0;
                let roomItem = infoRooms[idx];
                let base_rate = 0;
                let allInclusive = this.vars.allInclusive;
                let value_return = 0;
                let useVinculateRateplan = false;

                if (infoRooms.length) {
                    baseCost = infoRooms[0].base_rate;
                }

                if (!this.vars.roomsLinks) {
                    baseCost = infoRooms[idx].base_rate;
                }

                /*if(this.vars.base_cost_active){

                    //console.log('infoRooms', this.vars.base_cost, baseCost);
                    if(baseCost !== this.vars.base_cost){
                        idx = 1 ;
                    }
                    if(roomItem.base_rate === this.vars.base_cost){
                        idx = 0;
                    }
                    baseCost = this.vars.base_cost;

                }*/


                if (this.rateplan.rateplan_link.rateplan !== '' || this.rateplan.rateplan_link.rateplan !== '') {
                    //console.log('test', roomItem);
                    baseCost = rateplanFuntions.gapFunction(baseCost, this.rateplan.rateplan_link.value, this.rateplan.rateplan_link.type)
                    useVinculateRateplan = true;
                }


                if (allInclusive) {
                    value_return = baseCost;
                    let assignmentSelected = roomItem.assignments[type];
                    //console.log('assignmentSelected', assignmentSelected);
                    if (type !== 'childs' && type !== 'jrs') {
                        if (assignmentSelected.cant !== 2 && idx === 0) {

                            value_return = rateplanFuntions.gapFunction(baseCost, assignmentSelected.value, assignmentSelected.link)
                        }
                        if (assignmentSelected.cant === 2 && idx !== 0 && this.vars.roomsLinks) {

                            value_return = rateplanFuntions.gapFunction(baseCost, roomItem.base_rate, roomItem.room_link_type)
                        }


                        if (assignmentSelected.cant !== 2 && idx !== 0) {

                            if (this.vars.roomsLinks) {
                                value_return = rateplanFuntions.gapFunction(baseCost, roomItem.base_rate, roomItem.room_link_type)
                            }
                            value_return = rateplanFuntions.gapFunction(value_return, assignmentSelected.value, assignmentSelected.link)
                        }


                    }
                    if (type === 'childs') {
                        if (idx === 0) {
                            value_return = rateplanFuntions.gapFunction(baseCost, roomItem.children_price, roomItem.children_link_type)
                        } else {
                            if (this.vars.roomsLinks) {
                                value_return = rateplanFuntions.gapFunction(baseCost, roomItem.base_rate, roomItem.room_link_type)
                            }
                            value_return = rateplanFuntions.gapFunction(value_return, roomItem.children_price, roomItem.children_link_type)
                        }

                    }

                    if (type === 'jrs') {
                        if (idx === 0) {
                            value_return = rateplanFuntions.gapFunction(baseCost, roomItem.jrs_price, roomItem.jr_link_type)
                        } else {
                            if (this.vars.roomsLinks) {
                                value_return = rateplanFuntions.gapFunction(baseCost, roomItem.base_rate, roomItem.room_link_type)
                            }
                            value_return = rateplanFuntions.gapFunction(value_return, roomItem.jrs_price, roomItem.jr_link_type)
                        }

                    }


                } else {
                    if (type === 'base_rate') {
                        value_return = baseCost;
                        if (idx !== 0) {
                            value_return = rateplanFuntions.gapFunction(baseCost, roomItem.base_rate, roomItem.room_link_type)
                        }

                        if (useVinculateRateplan && !this.vars.roomsLinks) {
                            value_return = rateplanFuntions.gapFunction(roomItem.base_rate, this.rateplan.rateplan_link.value, this.rateplan.rateplan_link.type)
                        }
                    }
                    if (type === 'extra_pax') {
                        if (idx === 0) {
                            value_return = rateplanFuntions.gapFunction(baseCost, roomItem.extra_pax, roomItem.room_link_type_pax)
                        } else {
                            value_return = rateplanFuntions.gapFunction(baseCost, roomItem.base_rate, roomItem.room_link_type)
                            value_return = rateplanFuntions.gapFunction(value_return, roomItem.extra_pax, roomItem.room_link_type_pax)
                        }

                        /*if(useVinculateRateplan && !this.vars.roomsLinks){
                            value_return = roomItem.extra_pax
                        }*/

                    }
                }

                /*if(this.rateplan.rateplan_link.rateplan!=='' || this.rateplan.rateplan_link.rateplan!==''){
                    //console.log('test', roomItem);
                    value_return = rateplanFuntions.gapFunction(value_return, this.rateplan.rateplan_link.value, this.rateplan.rateplan_link.type)
                    //useVinculateRateplan = true;
                }*/


                return '$ ' + rateplanFuntions.formatMoney(value_return);
            },
            roomInfo(n) {
                let infoRoom = this.rateplan.infoRooms[n];
                let rooms = this.vars.rooms;
                let rateplanInfo = this.rateplan;
                let property = this.vars.property;
                let dataRoom = this.vars.rooms.find(item => item._id === infoRoom.room)
                this.determineHeaders();
                this.checkDifference(n, 'change');
                let allInclusive = this.vars.allInclusive;


                let assignments = [];

                //console.log('dataRoom', dataRoom, property);


                if (allInclusive) {

                    for (let i = 1; i <= dataRoom.max_adults; i++) {
                        let modelassignment = {
                            _id: null,
                            cant: i,
                            value: ''
                        }

                        assignments.push(modelassignment);
                    }
                    this.determineHeadersExtra();
                }else{
                    infoRoom.max_capacity = dataRoom.max_adults;
                    infoRoom.base_pax = 2;

                }
                if (this.vars.roomsLinks) {
                    if (n === 0) {
                        infoRoom.isBaseRate = true;
                        infoRoom.room_link = null;
                        infoRoom.room_link_type = null;
                    } else {
                        infoRoom.isBaseRate = false;
                        infoRoom.room_link = this.rateplan.infoRooms.find(itemE => itemE.isBaseRate === true).room;
                    }
                } else {
                    infoRoom.isBaseRate = false;
                }
                infoRoom.isBaseRate = (n === 0 && this.vars.roomsLinks)
                infoRoom.max_adults = dataRoom.max_adults;
                infoRoom.jrs_status = this.showJrs(property, dataRoom, 'general');
                infoRoom.children_status = this.showChilds(property, dataRoom, 'general');
                infoRoom.assignments = assignments;
                if (rateplanInfo.rateplan_link.rateplan && rateplanInfo.rateplan_link.rateplan !== '') {
                    let roomFinded = this.vars.rateConfigsBase.find(item => item.room === dataRoom._id);
                    roomFinded.assignments = this.constructRateConfing(roomFinded);
                    roomFinded._id = '';
                    this.rateplan.infoRooms[n] = roomFinded
                }


                //console.log('roomInfo', infoRoom, rateplanInfo)
            },
            determineHeaders() {
                let allInclusive = this.vars.allInclusive;
                let titles = this.generalInfo.info_links.title;
                let titlesAi = this.generalInfo.info_links.title.extendible;
                let roomsActives = this.rateplan.infoRooms;

                //console.log('roomsActives', roomsActives)
                this.vars.headers = [];
                if (allInclusive) {
                    let maxSize = 2;
                    roomsActives.forEach(item => {
                        let dataRoom = this.vars.rooms.find(element => element._id === item.room)
                        //console.log('dataRoom', dataRoom)
                        if (dataRoom) {
                            maxSize = dataRoom.max_adults > maxSize ? dataRoom.max_adults : maxSize;
                        }
                    })

                    //console.log('roomInfo', maxSize)
                    for (let i = 0; i < maxSize; i++) {
                        this.vars.headers.push(titlesAi[i]);
                    }

                } else {
                    this.vars.headers = titles.normal;
                }

            },
            determineHeadersExtra() {
                let roomsActives = this.rateplan.infoRooms;
                let property = this.vars.property;
                let showJrs = this.showJrs(property, roomsActives);
                let showChilds = this.showChilds(property, roomsActives);
                this.vars.headers_extras = [];
                if (showJrs) {
                    this.vars.headers_extras.push('Juniors');
                }
                if (showChilds) {
                    this.vars.headers_extras.push('Niños');
                }
            },
            showJrs(property, rooms, type = 'headers') {
                let statusReturn = false;
                if (property.only_adults) {
                    return false;
                }
                if (!property.age_jr || property.age_jr === null) {
                    return false;
                }
                if (type === 'headers') {
                    rooms.forEach(item => {

                        let dataRoom = this.vars.rooms.find(element => element._id === item.room)
                        if (dataRoom.max_jr > 0) {
                            statusReturn = true;
                        }
                    })
                } else {
                    statusReturn = (rooms.max_jr > 0);
                }

                return statusReturn;
            },

            showChilds(property, rooms, type = 'headers') {
                let statusReturn = false;
                if (property.only_adults) {
                    return false;
                }
                if (!property.age_child || property.age_child === null) {
                    return false;
                }
                if (type === 'headers') {
                    rooms.forEach(item => {

                        let dataRoom = this.vars.rooms.find(element => element._id === item.room)
                        if (dataRoom.max_children > 0) {
                            statusReturn = true;
                        }
                    })
                } else {
                    statusReturn = (rooms.max_children > 0);
                }

                return statusReturn;
            },
            calcWidth(value) {
                let val = 120;
                if (this.vars.allInclusive) {
                    val = value;
                }

                let total = this.vars.headers.length;

                return val * total + 220;
            },

            roomName(room_id) {
                //console.log('roomName', room_id)
                if (room_id) {
                    let rooms = this.vars.rooms;

                    let room_info = rooms.find(itm => itm._id === room_id)

                    return room_info.name;
                }


            },
            checkClassBase(simple_class, base_room) {
                let stringText = '';
                if (!simple_class) {

                    stringText += 'simple-class ';
                }
                if (base_room && simple_class) {

                    stringText += ' base_class';
                }
                return stringText;
            },
            changeLinks() {
                let roomsActives = this.rateplan.infoRooms;
                if (roomsActives.length) {
                    roomsActives[0].isBaseRate = this.vars.roomsLinks;
                }
                if (this.vars.roomsLinks) {
                    if (roomsActives.length > 1) {
                        roomsActives.forEach((item, idx) => {
                            if (idx > 0) {
                                item.room_link = roomsActives[0].room
                            }
                        })
                    }
                }

            },

            async updatesRacks(contract_id, save = false) {

                /*let rates = this.rateplan.rates_list;
                let rates_old = this.generalInfo.rates_list;*/
                let ratesRacks = this.rateplan.rates_list;
                if (save) {

                    rateplanService.createRateRack(contract_id, ratesRacks);
                } else {

                    rateplanService.updateRateRack(contract_id, ratesRacks);
                }


            },
            showSwal(msg) {

                swal({
                    title: this.$t('contract.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(() => {
                    this.$router.push({
                        name: 'Contracts'
                    });
                });
            },
            alertError() {
                swal({
                    title: 'Código de contrato ya existe',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: 'Modificar',
                    buttonsStyling: false
                }).then((result) => {

                });

            },
            isAvailable(room){
                let rateconfigsBD = this.vars.rateconfigsLinksBD;
                let count_rateplan  =rateconfigsBD.some(rateConfig=> rateConfig.room === room)
                //console.log(count_rateplan, 'count_rateplan');
                return !count_rateplan;
            },



            alertLinkMsg(n){
                let htmlList = ''
                let rateplans = [];
                let rateplansDB = this.rateplan.linked_rateplans;
                let rateconfigsBD = this.vars.rateconfigsLinksBD;
                let room = this.rateplan.infoRooms[n];
                let count_rateplan =rateconfigsBD.some(rateConfig=> rateConfig.room === room.room)
                if(count_rateplan){
                    let rateplansLinks = rateconfigsBD.filter(rateConfig=> rateConfig.room === room.room)
                    rateplansLinks.forEach(element=>{

                        //console.log('rateplanInfo', rateplansLinks);
                        let rateplanInfo = rateplansDB.find(item=> item._id === element.rateplan);
                        //console.log('rateplanInfo', rateplansLinks);
                        if(rateplanInfo){
                            rateplans.push(rateplanInfo)
                        }
                    })
                }



                let showAdvertency = false;

                htmlList += '<p class="pl-3 pr-3">No puedes eliminar esta habitación porque la tienes agregada en los contratos:</p>'
                htmlList+= '<div class="text-left box-info-rateplans">'
                rateplans.forEach(rateplan=>{
                    let routeData = this.$router.resolve({name: 'editContract', params: {id: rateplan._id}});
                    htmlList +='<a href="'+routeData.href+'" target="_blank"> '+rateplan.name+' <i class="el-icon-edit ml-2"></i></a><br>';
                    showAdvertency = true;
                })
                htmlList+= '</div>'

                if(showAdvertency){
                    swal({
                        title: 'Advertencia',
                        type: 'warning',
                        html:htmlList,
                        confirmButtonText: 'Aceptar',
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill'
                    }).then(()=> {
                       /* this.$router.push({
                            name: 'Contracts'
                        })*/
                    });
                }


                return showAdvertency;
                //console.log('costVinculate', room_id, idIs, this.rateplan.rateplan_link.rateplan);
            },

            isNumberKey(event)
            {
                var charCode = event.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    event.preventDefault();
                } else {
                    return true;
                }
            },

            toFixed(num, fixed) {

                //num = num.replace(/\D/g,'');
                if(isNaN(parseFloat(num)) || !isFinite(num)){
                    num =  num.replace(/[^\d.-]/g, '')
                }
                var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
                //console.log('re', re);
                return num.toString().match(re)[0];

                //Math.round(floatNumber*100)/100;
            },

            /*************************Calculos**************************/

        }
    }

</script>
<style lang="scss">
    .form-check {
        &.is_public {
            margin-top: 35px;
            .form-check-label {
                text-transform: uppercase;
            }
        }
    }

    .card label.subtitle {
        text-transform: none;
        color: #000;
    }

    .container-images {
        padding-top: 35px;
        .principal {
            width: 100%;
            height: 175px;
            margin-bottom: 10px;
        }
        .second {
            width: 30%;
            margin-left: 5%;
            &.first {
                margin-left: 0;
            }
        }
    }

    .title-b {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
        font-weight: 400;
    }

    .box-rooms {
        width: 100%;
        margin-bottom: 25px;
        .text-muestra {
            font-size: 14px;
            line-height: 40px;
            margin-bottom: 0;
        }
        .item-minus {
            line-height: 45px;
        }
        .form-group {
            margin-bottom: 0;
        }

    }

    .btn-plus {
        -webkit-box-shadow: 0 10px 6px -4px #777;
        -moz-box-shadow: 0 10px 6px -4px #777;
        box-shadow: 0 10px 6px -4px #777;
        width: 35px;
        height: 35px;
        font-size: 28px;
        background-color: black;
        display: inline-block;
        border-radius: 100%;
        position: relative;
        right: 0;
        i {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 4px;
            bottom: 0px;
            vertical-align: middle;
            display: block;
            color: #fff;

        }
    }

    .box-button {
        //padding-left: 10px;
        .add-item {
            width: 100%;
            height: 40px;
            color: #fff;
            background-color: #222222;
            border: 2px solid #000;
        }
    }

    .btn-action {
        line-height: 45px;
        font-size: 25px;
        margin-left: 15px;
    }

    .card {
        .lbl-main {
            font-weight: 600;
            margin-top: 50px;
        }

        .pt-custom {
            margin-top: 26px !important;
        }

    }

    .el-tag, .el-tag.el-tag--default {
        background-color: #eee;
    }

    .custom-error {
        label.text-danger {
            text-transform: none;
            white-space: nowrap;
            font-size: 80%;
        }
        &.mbis-0 {
            .form-group {
                margin-bottom: 0;
            }
        }
    }

    span.item-empty {
        font-size: 11px;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-style: italic;
        color: #CACACA;
        display: inline-block;
    }

    .form-rates-ext {
        label {
            width: 70px;
        }
        .form-group {
            width: calc(100% - 100px);
            display: inline-block;
        }
        span {
            width: 30px;
        }
        span.plus-item {
            float: right;
            font-size: 25px;
        }
    }

    .box-contain-rooms {
        padding: 0 15px;
        display: inline-block;
        width: 100%;
        &.scoll-apply {
            padding-bottom: 5px;
            width: 95%;
            overflow-x: scroll;
        }

        .box-extras {
            width: auto;
            display: inline-block;
            white-space: nowrap;
            &.head-rooms {
                .item-rate {
                    text-align: center;
                    line-height: 35px;
                }
            }
            .item-rate {
                display: inline-block;
                width: 170px;

                &.roomlink-class {
                    width: 120px;
                    margin-right: 0;
                }
            }

            .item-rate {
                padding: 0 5px;
                .input-default {
                    width: 57%;
                    display: inline-block;
                    margin-right: 3%;
                    margin-bottom: 0;
                    &.simple-class {
                        width: 100%;
                        margin-right: 0;
                    }

                }

                .el-select .el-input__inner {
                    text-align: center;
                }
            }
        }
        .inside-left {
            width: auto;
            display: inline-block;
            white-space: nowrap;
            &.ep-class {
                width: 75%;
            }
            &.head-rooms {
                .item-rate {

                    text-align: center;
                    line-height: 35px;
                }
            }
            .box-room {
                width: 220px;
                display: inline-block;
                .select-default {
                    width: 100%;
                }
            }
            .box-ep {
                display: inline-block;
                .item-rate {
                    display: inline-block;
                    width: 170px;
                }
            }

            .box-ai {
                width: auto;
                display: inline-block;
                white-space: nowrap;

                .item-rate {
                    display: inline-block;
                    width: 170px;

                    &.roomlink-class {
                        width: 120px;
                        margin-right: 0;
                    }
                }
            }

            .box-resume-ep {
                width: 200px;
                margin-left: 20px;
                display: inline-block;
                background-color: #FFFFFF;
                border: 1px solid #E3E3E3;
                border-radius: 4px;
                font-size: .875rem;
                color: #565656;
                .item-resume {
                    text-align: center;
                    display: inline-block;
                    width: 50%;
                    line-height: 33px;
                    label {
                        margin-bottom: 0;
                    }
                }
            }

            .item-rate {
                padding: 0 5px;
                .input-default {
                    width: 57%;
                    display: inline-block;
                    margin-right: 3%;
                    margin-bottom: 0;
                    &.simple-class {
                        width: 100%;
                        margin-right: 0;
                    }

                }
                .el-select .el-input__inner {
                    text-align: center;
                    margin-bottom: 0;
                }
            }
        }
        .item-minus {
            display: inline-block;
            width: 5%;
            text-align: center;
            position: absolute;
            right: 0;
            line-height: 35px;

        }

        .select-default {
            width: 40%;
            display: inline-block;
            &.simple-class {
                display: none;
            }
            &.all-width{
                width: 100%;
            }
        }
        .el-select .el-input__inner {
            padding: 0 15px 0 5px;

        }
        .el-input__suffix {
            right: -3px;
        }
        .header-rooms {
            .box-resume-ep {
                border: 0;
            }
        }
    }

    .input-default {
        &.base_class {
            input {
                border: 2px solid #049DD9;
            }
        }
    }

    .box-contain-rooms .inside-left .box-room .select-default {
        &.with-icon {
            width: calc(100% - 25px);
        }
        &.no-icon {
            /*margin-left: 25px;
            width: calc(100%  - 25px);*/
            width: 100%;
        }
    }

    .space-icon {
        width: 25px;
    }

    .row-item {
        width: auto;
        display: inline-block;
        white-space: nowrap;
        margin-bottom: .75rem;
    }

    .header-rooms {
        width: 100%;
        display: inline-block;
        white-space: nowrap;

    }

    .box-resume-ai {
        .bx-left {
            display: inline-block;
        }
        .item-header {
            .item-resume label {
                font-weight: 600;
            }
        }
        .item-header, .element-room {
            white-space: nowrap;
        }
        .item-room {
            width: 220px;
            display: inline-block;
            label{
                font-weight: 600;
                &.fw-300{
                    font-weight: 300;
                }
            }
        }
        .item-resume {
            width: 170px;
            display: inline-block;
            text-align: center;

        }
    }

    .all-container {
        text-align: center;
        label {
            font-size: 14px;
            font-weight: 600;
        }
    }
    .box-info-rateplans{
        a{
            font-weight: 600;
            color: #6B3E91;
        }
        ul{
            padding-left: 30px;
        }
        font-size: 15px;
        padding: 0 25px;
    }
</style>
